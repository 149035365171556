import { Collapse } from "bootstrap";
import Swiper, { Scrollbar } from "swiper";
import { Datepicker, DateRangePicker } from 'vanillajs-datepicker';
import ru from 'vanillajs-datepicker/locales/ru';

Object.assign(Datepicker.locales, ru);

document.addEventListener("DOMContentLoaded", () => {
    // Menu toggle
    const elHamburger = document.querySelector(".hamburger");
    const elCollapse = document.getElementById("navbarMenu");

    elCollapse.addEventListener("show.bs.collapse", () => {
        elHamburger.classList.add("is-active");
    });

    elCollapse.addEventListener("hidden.bs.collapse", () => {
        elHamburger.classList.remove("is-active");
    });

    // Swiper
    const elsSwiper = document.querySelectorAll('.swiper');

    elsSwiper.forEach((elSwiper) => {
        const elScrollBar = elSwiper.querySelector('.swiper-scrollbar');
        
        const swiper = new Swiper(elSwiper, {
            modules: [Scrollbar],
            slidesPerView: 1,
            spaceBetween: 24,
            scrollbar: {
              el: elScrollBar,
              draggable: true
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                },
              },
        });
    })

    // Data Picker
    const elDatePicker = document.getElementById('datePicker');

    if (elDatePicker) {
        const rangepicker = new DateRangePicker(elDatePicker, {
            format: 'dd.mm.yy',
            language: 'ru'
        })
    }

});
